import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import HomePage from "./components/home/HomePage";
import AboutPage from "./components/about/AboutPage";
import ContactPage from "./components/contact/ContactPage";
import { AppProvider } from "./context/AppContext";
import { LanguageProvider } from "./context/LanguageContext";
// import LoginPage from "./components/home/LoginPage";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import NewsDetails from "./components/news/NewsDetails";
import EstimateForm from "./components/estimate/EstimateForm";
import "./App.css";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize("G-L6SWZ8Q030");
  ReactGA.send("pageview");
  return (
    <AppProvider>
      <LanguageProvider>
        <Router>
          <div className="App">
            <Header />
            <div className="App-content">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/news/:id" element={<NewsDetails />} />
                <Route path="/estimate" element={<EstimateForm />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </LanguageProvider>
    </AppProvider>
  );
}

export default App;
