import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "@react-spring/web";

const ResultContainer = styled(animated.div)`
  margin-top: 20px;
  font-size: 24px;
`;

const Result = ({ estimate }) => {
  const { number } = useSpring({ number: estimate, from: { number: 0 } });

  return (
    <ResultContainer>
      <animated.div>
        {/* Estimated Price: ${number.to((n) => n.toFixed(2))} */}
        <h1>hello</h1>
      </animated.div>
    </ResultContainer>
  );
};

export default Result;
