import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  color: #000;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
`;

const Question = ({ question, onAnswer }) => (
  <QuestionContainer>
    <h3>{question.text}</h3>
    <ButtonGroup>
      <Button variant="success" onClick={() => onAnswer(question.id, true)}>
        YES
      </Button>
      <Button variant="danger" onClick={() => onAnswer(question.id, false)}>
        NO
      </Button>
    </ButtonGroup>
  </QuestionContainer>
);

export default Question;
