import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import "../../assets/styles/home/HomePage.css";
import ServiceSection from "./ServiceSection";
import HeroSection from "./HeroSection";
import PortfolioSection from "./PortfolioSection";
import ActionSection from "./ActionSection";
import NewsSection from "./NewsSection";

function HomePage() {
  return (
    <div className="home-page">
      <HeroSection />
      <ServiceSection />
      <PortfolioSection />
      <ActionSection />
      <NewsSection />
    </div>
  );
}

export default HomePage;
