import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

const StartScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #000;
`;

const StartScreen = ({ onStart }) => (
  <StartScreenContainer>
    <h1>Welcome to the Estimate App</h1>
    <Button variant="primary" onClick={onStart}>
      Start
    </Button>
  </StartScreenContainer>
);

export default StartScreen;
