import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { Card } from "react-bootstrap";
import Question from "./Question";
import Result from "./Result";
import StartScreen from "./StartScreen";

const questionsData = [
  { id: 1, text: "Do you need a website?", value: null },
  { id: 2, text: "Do you need SEO services?", value: null },
  { id: 3, text: "Do you need a mobile app?", value: null },
  { id: 4, text: "Do you need e-commerce functionality?", value: null },
  { id: 5, text: "Do you need ongoing support?", value: null },
];

const FormContainer = styled(animated.div)`
  padding: 30px;
  color: #000;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const EstimateForm = () => {
  const [questions, setQuestions] = useState(questionsData);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [estimate, setEstimate] = useState(null);
  const [started, setStarted] = useState(false);

  const formAnimation = useSpring({ opacity: 1, from: { opacity: 0 } });

  const handleStart = () => {
    setStarted(true);
  };

  const handleAnswer = (id, answer) => {
    const updatedQuestions = questions.map((question) =>
      question.id === id ? { ...question, value: answer } : question
    );
    setQuestions(updatedQuestions);

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      calculateEstimate(updatedQuestions);
    }
  };

  const calculateEstimate = (questions) => {
    const total = questions.reduce((sum, question) => {
      if (question.value) {
        return sum + 100; // YESの回答ごとに100を加算
      }
      return sum;
    }, 0);
    setEstimate(total);
  };

  return (
    <CenteredContainer>
      <Card style={{ width: "100%", maxWidth: "600px", margin: "auto" }}>
        <Card.Body>
          <FormContainer style={formAnimation}>
            {!started ? (
              <StartScreen onStart={handleStart} />
            ) : estimate === null ? (
              <Question
                question={questions[currentQuestionIndex]}
                onAnswer={handleAnswer}
              />
            ) : (
              <Result estimate={estimate} />
            )}
          </FormContainer>
        </Card.Body>
      </Card>
    </CenteredContainer>
  );
};

export default EstimateForm;
